import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import store from './store'
import i18n from './i18n'
import axios from 'axios'

import Apiclient from './Apiclient'
// import { bodyRender } from 'vicdun-notification'
import { NotificationContainer, Notification } from './plugin/notification'

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(NotificationContainer)
app.config.globalProperties.axios = axios
app.config.globalProperties.Apiclient = Apiclient
app.config.globalProperties.Notification = Notification
app.config.globalProperties.urls = {
    cdnUrl: 'https://cdn.eticpos.com',
    gatewayImageSRC: 'https://cdn.eticpos.com/general/img/gateways/'
}
app.mount('#eticposApp')

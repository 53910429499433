import _ from 'lodash'
import { createI18n, useI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

function getLang() { /* eslint-disable-line space-before-function-paren */
  if (_.isEmpty(localStorage.getItem('locale')) === true || _.isNull(localStorage.getItem('locale')) === true) {
    return process.env.VUE_APP_I18N_LOCALE
  } else {
    return localStorage.getItem('locale')
  }
}

getLang()

export default createI18n({
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
  fallbackWarn: false,
  missingWarn: false,
  legacy: false,
  locale: getLang(), // process.env.VUE_APP_I18N_LOCALE || 'en-EN',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-EN',
  messages: loadLocaleMessages()
})
<template>
<div>
  <Breadcrumb  :data="[ { default: true, title: $t('page.gateway.subMenu.listGatewaysSettings.title'), action: 'payment' } ]" />
  <div class="container gatewaySettingsContainer">
    <div class="tabList">
      <div class="buttonContainer">
        <ul>
          <template v-for="(gateway, index) in listData">
            <li v-if="gateway.status === 'active'">
              <button v-on:click="setTab(index)" :class="{active: tab === index}">
                <img v-bind:src="urls.gatewayImageSRC+gateway.id_gateway.toString().toLowerCase() + '.png'" class="gatewayLogo" height="40">
              </button>
            </li>
          </template>
        </ul>
      </div>
      <div class="contentContainer">
        <template v-for="(gateway, index) in listData">
          <div :class="[{active: tab === index}, gateway.id_gateway, 'tab-item']" v-if="gateway.status === 'active'">
            <h4>{{ gateway.display_name }}</h4>
            <div v-for="(params, key) in gateway.config_params" :key="params" class="paramsContainer">
              <b>{{ params.label }}</b>
              <div v-if="params.type === 'text'">
                <input type="text"
                :name="key"
                @input="gateway.inputparams.params[key] = $event.target.value"
                :value="params.current_value"
                :min="params.min_size"
                :minlength="params.min_size"
                :max="params.size"
                :maxlength="params.size"
                :required="params.required"
                />
              </div>
              <div v-else-if="params.type === 'select'">
                <select :name="key" @input="gateway.inputparams.params[key] = $event.target.value">
                  <option v-for="(value, key) in gateway.config_params.test_mode.values" :key="key" :value="key" :selected="key === params.current_value">{{ value }}</option>
                </select>
              </div>
              <div v-else>
                <div class="cardnetworkContainer">
                  <span v-for="cardnetwork in gateway.supported_cardnetworks" :key="cardnetwork">
                    {{ cardnetwork }}
                  </span>
                </div>
              </div>
            </div>
            <div class="actionButtonContainer">
              <button type="button" class="saveParams" v-on:click="saveGatewaySetting(gateway.inputparams)">
                {{ $t('general.action.save') }}
              </button>
              <button type="button" class="uninstallGateway" v-on:click="uninstallGateway(gateway.id_gateway)">
                {{ $t('general.action.uninstall') }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@/theme/default/components/Breadcrumb.vue';
import _ from "lodash";
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      listData: {},
      tab: 0
    }
  },
  mounted() {
    this.listGateway()
  },
  methods: {
    setTab(index) {
      this.tab = index
    },
    saveGatewaySetting(params) {
      console.log(params)
      this.Apiclient.SaveGatewaySetting(params).then(res => {
        // if res.info
        console.log("info",res)
        if (res.status) {
          this.Notification('info', 'right-bottom', null, res.body.info, 5, true)
        }
        if (res.message.error.details.error_messages.length > 0) {
          this.Notification('danger', 'right-bottom', null, res.message.error.details.error_messages, 5, true)
        }
      })
    },
    listGateway: function () {
      this.setTab(0)
      this.listData = {}
      this.Apiclient.ListGatewaysSetting().then(res => {
        // console.log(res.body);
        this.listData = res.body.gateway_list.filter(resData => {
          resData.inputparams = {
            id_gateway: resData.id_gateway,
            params: {}
          }
          // console.log("resData", resData.config_params)
          _.forEach(resData.config_params, function (value, key) {
            resData.inputparams.params[key] = value.current_value
          })
          return resData.status === 'active';
        });
        // this.listData = res.body.body.gateway_list
      })
    },
    uninstallGateway(name) {
      console.log(name);
      this.Apiclient.UninstallGateway(name).then(res => {
        if (res.status) {
          this.Notification('success', 'right-bottom', null, res.message, 5, true)
          this.listGateway()
        }
        console.log(res);
      })
    },
    statusControl(status) {
      console.log(status)
      // return _.eq(status, 'active') ? true : false
    }
  }
}
</script>

<style lang="scss">
.gatewaySettingsContainer {
  padding-top: 25px!important;
  padding-bottom: 25px!important;
  @media (min-width: 640px) {
    padding: 25px 0 !important;
  }
  .tabList {
    display: flex;
    gap: 1rem;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    .buttonContainer {
      @media (min-width: 1024px) {
        width: 25%;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: .5rem;
        @media (min-width: 768px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
        @media (min-width: 1024px) {
          display: flex;
          flex-direction: column;
        }
        li {
          button {
            width: 100%;
            padding: .5rem;
            font-size: large;
            background-color: rgba(240, 248, 255, 0);
            border: 1px solid #dedede;
            border-radius: 7px;
            margin-bottom: 10px;
            &.active {
              background-color: #158c086f;
            }
          }
        }
      }
    }
    .contentContainer {
      @media (min-width: 1024px) {
        width: 75%;
      }
      .tab-item {
        border: 1px solid #dedede;
        border-radius: 7px;
        padding: .5rem;
        display: none;
        &.active {
          display: block;
        }
        .paramsContainer {
          b {
            font-size: large;
            margin-bottom: 3px;
            display: block;
          }
          * {
            margin-bottom: 7px;
            input, select {
              border: 1px solid #dedede;
              border-radius: 7px;
              padding: .5rem;
              outline: 0;
              display: block;
              width: 100%;
              box-sizing: border-box;
            }
          }
        }
        .cardnetworkContainer {
          display: flex;
          justify-content: center;
          gap: .5rem;
          flex-wrap: wrap;
          * {
            padding: .2rem .5rem;
            border-radius: 50px;
            border: 1px solid #dedede;
          }
        }
        .actionButtonContainer {
          display: flex;
          gap: 1rem;
          margin-top: 1rem;
          button {
            color: #ffffff;
            border: 0;
            border-radius: 7px;
            padding: .5rem;
            font-size: large;
            &.saveParams {
              width: 75%;
              background-color: rgb(20, 119, 38);
            }
            &.uninstallGateway {
              width: 25%;
              background-color: rgb(119, 20, 20);
            }
          }
        }
      }
    }
  }
}
</style>

<template>
<div>
  <Breadcrumb  :data="[ { default: true, title: $t('page.campaigns.title'), action: 'payment' } ]" />
  <div class="container campaigns-pt-25">
    <div class="gridContainer" v-if="campaigns.status">
      <template v-for="banner in campaigns.banners">
        <a v-if="banner.show === true" :href="banner.url" class="item" :data-show="banner.show">
          <img :src="banner.image" :alt="banner.title" />
        </a>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@/theme/default/components/Breadcrumb.vue';
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      campaigns: []
    }
  },
  mounted() {
    this.$watch('$i18n.locale', (newQuestion) => {
      this.getCampaigns()
    })
    this.getCampaigns()
  },
  methods: {
    getCampaigns() {
      this.axios.get('https://cdn.eticpos.com/general/module/language/' + this.$i18n.locale + '/campaigns.json').then(res => {
        this.campaigns = res.data ?? []
      })
    }
  },
}
</script>

<style lang="scss">
  .campaigns-pt-25 {
    margin-top: 1rem!important;
    .gridContainer {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      @media (min-width: 550px) {
        //   grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (min-width: 640px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (min-width: 1024px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      @media (min-width: 1280px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .item {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #dedede;
        padding: 4px;
        border-radius: 4px;
        display: block;
        img {
          width: 100%;
          display: block;
          border-radius: 4px;
        }
      }
    }
  }
</style>

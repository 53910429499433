<template>
<div>
  <Breadcrumb  :data="[ { default: true, title: $t('page.gateway.title'), action: 'payment' } ]" />
  <div class="container">
    <div class="gatewaysContainer">
      <div class="gateway" v-for="gateway in allGateways" :key="gateway">
        <div class="header">
          <img loading="lazy" :src="urls.gatewayImageSRC+gateway.name.toString().toLowerCase() + '.png'" :alt="gateway.full_name" class="gatewayLogo">
        </div>
        <div class="body">
          <div class="supportedCardnetworks" style="word-break: break-word; margin-bottom: 10px;">
            <b>supported cardnetworks</b> <br /> 
            <div v-if="typeof gateway.supported_cardnetworks === 'string'">{{ gateway.supported_cardnetworks }}</div>
            <div v-else>
              <span v-for="(cardnetwork, index) in gateway.supported_cardnetworks" :key="cardnetwork">
                {{ cardnetwork }}{{ gateway.supported_cardnetworks.length > 1 && (gateway.supported_cardnetworks.length - 1) > index ? ', ' : '' }}
              </span>
            </div>
          </div>
          <div class="supportedCurrencies" style="word-break: break-word; margin-bottom: 10px;">
            <b>supported currencies</b> <br />
            <div v-if="typeof gateway.supported_currencies === 'string'">{{ gateway.supported_currencies }}</div>
            <div v-else>
              <span v-for="(currencies, index) in gateway.supported_currencies" :key="currencies">
                {{ currencies }}{{ gateway.supported_currencies.length > 1 && (gateway.supported_currencies.length - 1) > index ? ', ' : '' }}
              </span>
            </div>
          </div>
          <div class="supportedInstallments" style="word-break: break-word; margin-bottom: 10px;">
            <b>supported installments</b> <br />
            <div v-if="typeof gateway.supported_installments === 'string'">{{ gateway.supported_installments }}</div>
            <div v-else-if="gateway.supported_installments.length === 0">-</div>
            <div v-else>
              <span v-for="(installment, index) in gateway.supported_installments" :key="installment">
                {{ installment }}{{ gateway.supported_installments.length > 1 && (gateway.supported_installments.length - 1) > index ? ', ' : '' }}
              </span>
            </div>
          </div>
          <div class="supportedCountries" style="word-break: break-word">
            <b>supported countries</b> <br />
            <div v-if="typeof gateway.supported_countries === 'string'">{{ gateway.supported_countries }}</div>
            <div v-else>
              <span v-for="(countries, index) in gateway.supported_countries" :key="countries">
                {{ countries }}{{ gateway.supported_countries.length > 1 && (gateway.supported_countries.length - 1) > index ? ', ' : '' }}
              </span>
            </div>
          </div>
        </div>
        <div class="footer" v-if="setupControl(gateway.name)">
          <button class="unInstall" v-on:click="unInstallGateway(gateway.name)">{{ $t('general.action.uninstall') }}</button>
        </div>
        <div class="footer" v-else>
          <button class="install" v-on:click="installGateway(gateway.name)">{{ $t('general.action.setUp') }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@/theme/default/components/Breadcrumb.vue';
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      allGateways: {},
      allGatewaysData: {},
      merchantGateways: {}
    }
  },
  mounted() {
    this.listAllGateways()
  },
  methods: {
    installGateway(name) {
      console.log('name', name)
      this.Apiclient.InstallGateway(name).then(res => {
        console.log(res);
        if (res.status) {
          if (res.message?.error?.details?.error_messages?.length > 0) {
            this.Notification('warning', 'right-bottom', null, res.message.error.details.error_messages, 5, true)
          } else {
            this.Notification('success', 'right-bottom', null, res.message, 5, true)
            this.listAllGateways()
          }
        } else {
          this.Notification('danger', 'right-bottom', '', res.message.error.error_id + ' - ' + res.message.error.title, 5, true)
        }
      })
    },
    unInstallGateway(name) {
      this.Apiclient.UninstallGateway(name).then(res => {
        if (res.status && res.code === 200) {
          this.Notification('success', 'right-bottom', null, res.message, 5, true)
          this.listAllGateways()
        } else {
          console.log('unInstallGateway ---- ', res);
        }
      })
    },
    listAllGateways() {
      this.Apiclient.ListAllGateways().then(res => {
        this.allGateways = res.body.gateway_list
        // this.allGatewaysData = res.body.body.gateway_list
      })
      this.Apiclient.ListMerchantGateways().then(res => {
        this.merchantGateways = res.body.gateway_list
      })
    }, 
    setupControl(name) {
      return _.find(this.merchantGateways, function(gateway) {
        return _.isEqual(gateway.id_gateway, name) && _.isEqual(gateway.status, 'active')
      })
    }
  }
}
</script>

<style lang="scss">
  .gatewaysContainer {
    padding: 25px 0 !important;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    overflow-x: auto;
    .gateway {
      border: 1px solid #dedede;
      border-radius: 7px;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      .header {
        border-bottom: 1px solid #dedede;
        text-align: center;
        padding: .5rem;
        .gatewayName {
          text-align: center;
          margin: .5rem 0;
        }
        .gatewayLogo {
          height: 45px;
          margin: 0 auto;
          display: block;
        }
      }
      .body {
        flex: 1 1 auto;
        padding: .5rem;
        > * {
          border-bottom: 1px solid #dedede;
          padding-bottom: .5rem;
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
      .footer {
        border-top: 1px solid #dedede;
        padding: .5rem;
        button {
          border: 0;
          color:white;
          font-size: large;
          padding: .3rem .5rem;
          border-radius: 7px;
          width: 100%;
          cursor: pointer;
          &.install {
            background-color: #1a852f;
          }
          &.unInstall {
            background-color: #851a1a;
          }
        }
      }
    } 
    @media (min-width: 550px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 640px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (min-width: 1280px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media (min-width: 1536px) {}
  }
</style>

<template>
  <div class="container">
    <div class="homepage homepage-pt-25">
      <Card>
        <template v-slot:header>
          <h3 class="homepage-card-header-text">{{ $t('page.home.announcements.title') }}</h3>
        </template>
        <template v-slot:body v-if="announcements.loading === 200">
          <div class="announcements">
            <div v-for="announcement in announcements.data.list" :key="announcement" :class="['announcement', announcement?.type ?? '-']">
              <div class="publishText">{{ announcement?.message }}</div>
              <div class="publishDate">{{ announcement?.date }}</div>
            </div>
            <div class="announcement-footer">
              <div class="announcement-pagination" v-if="totalPage > 1">
                <button v-for="i in totalPage" :key="i" v-on:click="listData(i)" :class="{'active': announcements.page === i}">{{ i }}</button>
              </div>
              <button v-on:click="getAnnouncements()" class="refresh">
                <img :src="require('@/assets/svg/refresh.svg')" alt="refresh">
              </button>
            </div>
          </div>
        </template>
        <template v-slot:body v-else-if="announcements.loading === 100">
          <div>Duyru yok</div>
        </template>
        <template v-slot:body v-else>
          <div>Duyru Bulunamadı {{ announcements.loading }}</div>
        </template>
      </Card>
    </div>
  </div>
  <PaymentList type="home" class="homepage-mb-25" />
</template>

<script>
import PaymentList from '@/theme/default/views/payment/List.vue'
import Card from '@/theme/default/components/Card.vue'

export default {
  name: "Home",
  components: {
    PaymentList,
    Card
  },
  data() {
    return {
      announcements: {
        loading: 100, // 100 loading, 200 loaded, 300 failed to load
        data: {
          list: [],
          static: []
        },
        totalPage: 1,
        page: 1
      }
    }
  },
  mounted() {
    this.$watch('$i18n.locale', (newQuestion) => {
      this.getAnnouncements()
    })
    this.getAnnouncements()
  },
  methods: {
    getAnnouncements() {
      this.axios.get('https://cdn.eticpos.com/general/module/language/' + this.$i18n.locale + '/announcements.json').then(res => {
        this.announcements.loading = res.data.loading
        this.announcements.data.static = res.data.data
        this.totalPage = Math.ceil(res.data.data.length/5)
        this.listData()
      })
    },
    listData(page = 1) {
      this.announcements.data.list = [];
      this.announcements.page = page;
      for (let index = (page - 1) * 5; index < (page * 5); index++) {
        if (this.announcements.data.static[index] !== undefined) {
          this.announcements.data.list.push(this.announcements.data.static[index])
        }
      }
    }
  },
}
</script>

<style lang="scss">
.homepage-mb-25 {
  margin-bottom: 25px !important;
}

.homepage {
  &.homepage-pt-25 {
    padding-top: 25px;
  }

  .homepage-card-header-text {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .homepage-card-body-select {
    outline: none;
    background-color: transparent;
    border: 1px solid #dedede;
    padding: 7.5px 7.5px 7.5px 7.5px;
    border-radius: 7px;
    font-size: large;
    width: 100%;
  }

  .announcements {
    .announcement {
      display: flex;
      gap: .75rem;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #dedede;
      border-radius: 7px;
      margin-bottom: .7rem;
      padding: .7rem 1rem;
      color: var(--bs-alert-color);
      background-color: var(--bs-alert-bg);
      border: 1px solid var(--bs-alert-border-color);
      word-break: break-all;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.danger {
        --bs-alert-color: #842029;
        --bs-alert-bg: #f8d7da;
        --bs-alert-border-color: #f5c2c7;
      }

      &.warning {
        --bs-alert-color: #664d03;
        --bs-alert-bg: #fff3cd;
        --bs-alert-border-color: #ffecb5;
      }

      &.success {
        --bs-alert-color: #0f5132;
        --bs-alert-bg: #d1e7dd;
        --bs-alert-border-color: #badbcc;
      }

      &.info {
        --bs-alert-color: #055160;
        --bs-alert-bg: #cff4fc;
        --bs-alert-border-color: #b6effb;
      }

      &.default {
        --bs-alert-color: #141619;
        --bs-alert-bg: #d3d3d4;
        --bs-alert-border-color: #bcbebf;
      }
    }

    .announcement-footer {
      display: flex;
      gap: .2rem;

      button {
        border: 1px solid #dedede;
        border-radius: 7px;
        padding: .25rem .5rem;
      }

      .announcement-pagination {
        display: flex;
        gap: .2rem;

        button {
          border: 1px solid #dedede;
          border-radius: 7px;
          padding: .25rem .5rem;

          &.active {
            background: #dedede;
          }
        }
      }

      .refresh {
        padding: .5rem;
        img {
          width: 15px;
          height: 15px;
          margin-bottom: -3px;
        }
      }
    }
  }
}
</style>

<template>
<div>
  <Breadcrumb  :data="[ { default: true, title: $t('page.user.title'), action: 'payment' } ]" />
  <div class="container pageContainer" v-if="listData.length > 0">
    <div class="tableContainer">
      <table width="100%">
        <thead>
        <tr>
          <th>{{ $t('page.user.list.table.firstname') }} {{ $t('page.user.list.table.lastname') }}</th>
          <th>{{ $t('page.user.list.table.user_level') }}</th>
          <th>{{ $t('page.user.list.table.status') }}</th>
          <th>{{ $t('page.user.list.table.phone') }}</th>
          <th>{{ $t('page.user.list.table.email') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(data) in listData" :key="data">
          <td>{{ data.firstname }} {{ data.lastname }}</td>
          <td>{{ data.user_level }}</td>
          <td>{{ data.status }}</td>
          <td>{{ data.phone }}</td>
          <td>{{ data.email }}</td>
          <td>
            <button v-on:click="$store.commit('setTab', 'userEdit', data)">Edit</button>
<!--              <div class="action">-->
<!--                <button>•••</button>-->
<!--                <div class="actionContainer">-->
<!--                  asdasdasd-->
<!--                  asdasdasd-->
<!--                </div>-->
<!--              </div>-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@/theme/default/components/Breadcrumb.vue';
export default {
  name: "List",
  components: {
    Breadcrumb
  },
  data () {
    return {
      listData: {}
    }
  },
  mounted() {
    this.getAll()
  },
  methods: {
    getAll(){ // body.list.items
      this.Apiclient.UserList().then(res => {
        if (!res.status) {
          return this.Apiclient.redirectToLoginPage()
        }
        this.listData = res.body.list.items
      })
    }
  }
}
</script>

<style lang="scss">
.pageContainer {
  .tableContainer {
    table {
      border-top: 1px solid #dedede;
      border-spacing: 0;
      text-align: left;
      thead {
        tr {
          th {
            padding: 10px 5px;
            border-bottom: 1px solid #dedede;
          }
        }
      }
      tbody {
        tr {
          align-items: center!important;
          vertical-align: middle!important;
          td {
            padding: 7px 5px;
            border-bottom: 1px solid #dedede;
            align-items: center!important;
            vertical-align: middle!important;
            .action {
              position: relative;
              &:hover {
                .actionContainer {
                  display: block;
                  + #app {
                    background-color: rgba(10, 13, 38, 0.35);
                  }
                }
              }
              button {
                background-color: #fff;
                border: 0;
                width: 30px;
                height: 25px;
                &:hover {
                  border: 1px solid #dedede;
                  border-radius: 7px;
                }
              }
              .actionContainer {
                position: absolute;
                right: 0;
                z-index: 111;
                background-color: #ffffff;
                border: 1px solid #dedede;
                border-radius: 7px;
                padding: 7px;
                display: none;
                width: 200px;
              }
            }
            &:last-child {
              text-align: right;
            }
          }
          &:hover {
            background-color: #f6f8fa;
          }
        }
      }
    }
  }
}
</style>
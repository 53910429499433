<template>
  <div class="container pageContainer">
    <div class="tableContainer">

    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  mounted() {
    console.log(this.$store.getters.getTabParams)
  }
}
</script>

<style lang="scss">
</style>

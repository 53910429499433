export default {
  "general": {
    "action": {
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyala"])},
      "detaily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Git"])},
      "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndir"])},
      "installedInTheSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulu"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
      "setUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kur"])},
      "uninstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])}
    }
  },
  "page": {
    "campaigns": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalar"])}
    },
    "gateway": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemi"])},
      "subMenu": {
        "listAllGateways": {
          "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm ödeme yöntemleri"])}
        },
        "listGatewaysSettings": {
          "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemi ayarları"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemi ayarları"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemleri"])}
    },
    "home": {
      "announcements": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duyrular"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anasayfa"])},
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anasayfa"])}
    },
    "payment": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeler"])},
      "table": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kur"])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri"])},
        "custommer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri"])},
        "dateCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma Tarihi"])},
        "datePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Tarihi"])},
        "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemi"])},
        "lastTenPaymentsMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılan son 10 ödeme"])},
        "merchantReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza referans"])},
        "paymentInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme"])}
    },
    "paymentDetails": {
      "table": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
        "buyerFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı ücreti"])},
        "cancelable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iptal edilebilir"])},
        "dateCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma tarihi"])},
        "datePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme tarihi"])},
        "dateUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme tarihi"])},
        "gatewayFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemi ücreti"])},
        "gatewayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemi"])},
        "header": {
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
          "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme detayları"])},
          "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Yöntemi"])}
        },
        "merchantReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mazağa referans"])},
        "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net"])},
        "paymentInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme enstrüman"])},
        "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme linki"])},
        "paymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme şekli"])},
        "resultCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç kodu"])},
        "resultMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç mesajı"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Detay"])}
    },
    "platform": {
      "card": {
        "app_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Adresi"])},
        "home_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anasayfa"])}
      },
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])}
    },
    "user": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
      "list": {
        "table": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
          "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyadı"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
          "user_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı seviyesi"])}
        }
      },
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])}
    }
  }
}
<template>
<div>
  <Breadcrumb v-if="type !== 'home'" :data="[ { default: true, title: $t('page.payment.title'), action: 'payment' } ]" />
  <div class="container">
    <div class="tableContainer">
      <div v-if="type === 'home'" class="tableHeader">
        <h3>{{ $t('page.payment.table.lastTenPaymentsMade') }}</h3>
      </div>
      <table width="100%" id="">
        <thead>
        <tr>
          <th>{{ $t('page.payment.table.merchantReference') }}</th>
          <th>{{ $t('page.payment.table.amount') }}</th>
          <th>{{ $t('page.payment.table.currency') }}</th>
          <th>{{ $t('page.payment.table.paymentInstrument') }}</th>
          <th>{{ $t('page.payment.table.status') }}</th>
          <th>{{ $t('page.payment.table.dateCreate') }}</th>
          <th>{{ $t('page.payment.table.datePayment') }}</th>
          <th>{{ $t('page.payment.table.gateway') }}</th>
          <th>{{ $t('page.payment.table.customer') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          <template v-if="paymentList.length > 0 && status === true">
            <tr v-for="item in paymentList">
              <td>{{ item.merchant_reference }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.currency }}</td>
              <td>{{ item.payment_instrument }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.date_create }}</td>
              <td>{{ item.date_payment }}</td>
              <td>{{ item.gateway }}</td>
              <td>{{ item.customer }}</td>
              <td>
                <button class="detaily" v-on:click="getPaymentDetaily(item.id)">{{ $t('general.action.detaily') }}</button>
              </td>
            </tr>
          </template>
          <template v-else-if="paymentList.length >! 0 && status === true">
            <tr>
              <td colspan="10" align="center">Veri Bulunamadı</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="10" align="center">
                <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" height="90px" enable-background="new 0 0 0 0" xml:space="preserve">
                  <circle fill="#000" stroke="none" cx="6" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>    
                  </circle>
                  <circle fill="#000" stroke="none" cx="26" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate>       
                  </circle>
                  <circle fill="#000" stroke="none" cx="46" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate>     
                  </circle>
                </svg>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="tableBottom" v-if="type !== 'home'">
        <div class="itemCount">
          <span>{{ paymentList.length }} / {{ totalItems }}</span>
        </div>
        <div class="buttonContainer">
          <template v-for="i in totalPages">
            <button :class="{active: i === page}" v-on:click="getPayments(i, 20)" v-text="i"></button>
          </template>
        </div>
      </div> 
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@/theme/default/components/Breadcrumb.vue';
export default {
  name: "List",
  props: {
    type: String
  },
  components: {
    Breadcrumb
  },
  data () {
    return {
      status: false,
      page: 1,
      totalPages: 1,
      totalItems: 0,
      paymentList: {}
    }
  },
  mounted() {
    this.getPayments()
  },
  methods: {
    getPayments(page = 1, items_per_page = 20) {
      this.status = false
      this.page = page
      items_per_page = this.type === 'home' ? 10 : 20;
      this.Apiclient.PaymentList(page, items_per_page).then(res => {
        console.log(res)
        if (res.status) {
          this.totalPages = res.body.list.total_pages
          this.totalItems = res.body.list.total_items
          this.paymentList = res.body.list.items
          this.status = true
        }
      })
    },
    getPaymentDetaily(referance) {
      this.Apiclient.PaymentRetrieve(referance).then(res => {
        console.log(res.body.body.data)
        this.$store.commit('setTab', 'paymentDetaily');
        this.$store.commit('setParams', res.body.body.data);
      })
    }
  }
}
</script>

<style lang="scss">
.tableContainer {
  padding: 25px 0 0 0 !important;
  overflow-x: auto;
  .tableHeader {
    h3 {
      text-align: center;
      margin: 0 0 10px 0;
      padding: 0;
      font-weight: 600;
      font-size: larger;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    box-sizing: border-box;
    thead {
      background-color: #efefef;
      tr {
        td, th {
          border: 1px solid #ddd;
          text-align: center;
          padding: 5px 10px;
          height: 35px;
        }
      }
    }
    tbody {
      tr {
        td, th {
          border: 1px solid #ddd;
          text-align: center;
          padding: 5px 10px;
        }
        td {
          &:nth-of-type(odd) {
            background-color: #efefef;
            padding: 5px 10px;
          }
          button {
            &.detaily {
              padding: .2rem .5rem;
              border-radius: 40px;
              border: 0;
              background-color: #0a88b0;
              color: #ffffff;
              font-weight: 500;
              font-size: medium;
              cursor:pointer;
            }
          }
        }
      }
    }
  }
  .tableBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    width: 100%;
    .buttonContainer {
      display: flex;
      gap: .2rem;
      button {
        border: 1px solid #dedede;
        background-color: white;
        border-radius: 7px;
        width: 30px;
        height: 30px;
        &.active {
          background-color: #dedede;
        }
      }
    }
  }
}
</style>

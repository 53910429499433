<template>
<div>
  <Breadcrumb  :data="[ { default: true, title: $t('page.platform.title'), action: 'payment' } ]" />
  <div class="container platformContainer" v-if="platformList.length > 0">
    <div class="platforms">
      <div v-for="(data, index) in platformList" :key="data" class="platform">
        <img :src="data.logo_url ? data.logo_url : 'https://www.siriuscom.com/wp-content/uploads/2015/08/GettyImages-482569031.jpg'" loading="lazy" />
        <div class="body">
          <div class="buttonContainer">
            <a :href="data.home_url" target="_blank">
              <button>{{ $t('page.platform.card.home_url') }}</button>
            </a>
            <a :href="data.app_url" target="_blank">
              <button>{{ $t('page.platform.card.app_url') }}</button>
            </a>
          </div>
          <div class="description" v-if="data.description != null && data.description != ''">
            {{ data.description }}
          </div>
          <button class="unInstall" v-if="merchantPlatformControl(data.platform_code)">{{ $t('general.action.installedInTheSystem') }}</button>
          <button class="install" v-on:click="installPlatform(data.platform_code)" v-else>{{ $t('general.action.setUp') }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@/theme/default/components/Breadcrumb.vue';
import { notification } from 'vicdun-notification';
  
export default {
  name: "List",
  components: {
    Breadcrumb
  },
  data () {
    return {
      platformList: {},
      merchantPlatformList: {}
    }
  },
  mounted() {
    this.getAll()
  },
  methods: {
    getAll(){ // body.list.items
      this.Apiclient.PlatformList().then(res => {
        console.log(res);
        this.platformList = res.body.platform_list
      })
      this.Apiclient.MerchantPlatformList().then(res => {
        console.log(res);
        this.merchantPlatformList = res.body.merchantplatform_list
      })
    },
    merchantPlatformControl(platform_code) {
      return _.find(this.merchantPlatformList, function(platform) { return _.isEqual(platform.platform_code, platform_code) })
    },
    installPlatform(platform_code) {
      this.Apiclient.InstallPlatform(platform_code).then(res => {
        console.log(res);
        if (res.body.head.result_code === 1 && !res.body.head.result_message.includes('body.error_messages')) {
          notification({
            message: res.body.head.result_message,
            type: 'success',
            position: 'center-bottom',
            style: 'margin-bottom: 10px'
          })
          this.getAll()
        } else {
          notification({
            message: res.body.head.result_message.join("\n<br />"),
            type: 'danger',
            position: 'center-bottom',
            style: 'margin-bottom: 10px',
            timeout: 6
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .platformContainer {
    padding-top: 25px!important;
    padding-bottom: 25px!important;
    .platforms {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      .platform {
        // display: flex;
        // flex-direction: column;
        border: 1px solid #dedede;
        border-radius: 7px;
        position: relative;
        img {
          width: 100%;
          height: 250px;
          border-radius: 7px;
        }
        .header {
          background-color: #dedede;
          padding: .5rem;
          .title {
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            margin: 0 auto;
          }
        }
        .body {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #ffffff;
          border-top-left-radius: 40px;
          border-top-right-radius: 40px;
          padding: 0 .5rem .5rem .5rem;
          .buttonContainer {
            display: flex;
            justify-content: center;
            gap: .5rem;
            flex-wrap: wrap;
            margin-bottom: .5rem;
            border-bottom: 1px solid #dedede;
            padding: .5rem;
            button {
              background-color: #1e926b;
            }
          }
          .description {
            margin-bottom: 0.5rem;
          }
          button {
            border: 0;
            color:white;
            font-size: large;
            padding: .3rem .5rem;
            border-radius: 7px;
            width: 100%;
            cursor: pointer;
            &.install {
              background-color: #1a852f;
            }
            &.unInstall {
              background-color: #851a1a;
            }
          }
        }
      }

      @media (min-width: 550px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (min-width: 640px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      @media (min-width: 1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      @media (min-width: 1536px) {}
    }
  }
</style>

<template>
  <HeaderComponents />
  <div class="breadcrumb" v-if="breadcrumb === true">
    <div class="container">
      <h1 class="breadcrumbTitle">{{ breadcrumbTitle }}</h1>
      <h3 class="breadcrumbSubTitle" v-if="breadcrumbSubTitle.length > 0">{{ breadcrumbSubTitle }}</h3>
    </div>
  </div>
  <slot />
</template>

<script>
import HeaderComponents from '@/theme/default/components/Header'
export default {
  name: "Default",
  props: {
    breadcrumb: Boolean,
    breadcrumbTitle: String,
    breadcrumbSubTitle: String
  },
  components: {
    HeaderComponents
  }
}
</script>

<style lang="scss">
.breadcrumb {
  padding: 2.5rem 0;
  //border-bottom: 1px solid #dedede;
  background: #f0ffff;
  .breadcrumbTitle {
    text-align: center;
    font-size: 26px;
    margin: 0;
  }
  .breadcrumbSubTitle {
    text-align: center;
    font-size: 18px;
    margin: 0;
  }
}
</style>

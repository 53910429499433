import axios from 'axios'
import _ from 'lodash'
import store from './store'
import { notification } from 'vicdun-notification'
export class Apiclient {
    constructor() {
        this.apiUrl = 'https://sandbox.api.eticpos.com/v1/'
        this.apiSubUrl = ''
        this.apiMethod = 'GET'
        this.apiVersion = 'v1'
        this.headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    }

    Login(email, password) {
        // window.eticpos_config.platform
        return this.request('POST', 'user/gettoken', {
            "auth_query": {
                "auth_method": "email_password",
                "platform_code": window.eticpos_config.platform,
                "email": email,
                "password": password
            }
        })
    }

    UserList(page = 1, items_per_page = 20){
        return this.request('POST', 'user/list/', {
            "pagination": {
                "page": page,
                "items_per_page": items_per_page,
                "order_by": "id",
                "order_way": "ASC"
            },
            // "search": [
            //     {
            //         "where": "firstname",
            //         "operator": "contains",
            //         "what": "mut"
            //     },
            //     {
            //         "where": "firstname",
            //         "operator": "contains",
            //         "what": "mut"
            //     }
            // ]
        })
    }

    PaymentList(page = 1, items_per_page = 20) {
        return this.request('POST', 'payment/list', {
            'pagination': {
                'page': page,
                'items_per_page': items_per_page
            }
        })
    }

    PaymentRetrieve(referance) {
        return this.request('GET', 'payment/retrieve/' + referance)
    }

    ListAllGateways() {
      return this.request('GET', 'merchant/listAllGateways')
    }

    ListGatewaysSetting() {
      return this.request('GET', 'merchant/listGatewaysSetting')
    }

    InstallGateway(name) {
        console.log('name', 'merchant/installGateway/' + name)
      return this.request('GET', 'merchant/installGateway/' + name)
    }

    UninstallGateway(name) {
      return this.request('GET', 'merchant/uninstallGateway/' + name)
    }
    
    SaveGatewaySetting(params) {
        return this.request('POST', 'merchant/updateGatewaysSetting/', {
            gateway_update_list: [
                params
            ]
        })
    }

    ListMerchantGateways() {
      return this.request('GET', 'merchant/listMerchantGateways/')
    }

    PlatformList() {
      return this.request('GET', 'platform/list/')
    }

    InstallPlatform(platform_code) {
      return this.request('POST', 'merchantplatform/install/', {
        "platform": {
            'platform_code': platform_code,
            'store_url': window.eticpos_config.store_url,
            'platform_stage': "development",
            'merchant_platform_version': window.eticpos_config.merchant_platform_version
        }
      })
    }

    MerchantPlatformList() {
      return this.request('GET', 'merchantplatform/list/')
    }

    async request (method, apiSubUrl, params = {}) {
        if (_.isEmpty(window.eticpos_config.token) || _.isNull(window.eticpos_config.token)) {
            this.redirectToLoginPage()
        }
        if (apiSubUrl !== 'user/gettoken') { this.headers['Authorization'] = 'Bearer ' + window.eticpos_config.token }
        const config = { method, url: this.apiUrl + apiSubUrl, headers: this.headers, data: params }
        const response = await axios(config)
            .catch(e => {
                // console.log(e)
                // console.log(e.response.status)
                if (_.eq(e.response.status, 401)) {
                    notification({
                        class: '',
                        message: e.response.statusText,
                        position: 'center-bottom', // default: right-top, other positions: left-top, center-top, right-top, center, left-bottom, center-bottom, right-bottom
                        type: 'warning', // default, success, danger, warning, info
                        style: 'margin-bottom: 1rem; margin-top: 1rem;',
                        timeout: 3.5 // Default seconds: 3, you can write your own desired seconds. Example: 4.5
                    })
                    this.redirectToLoginPage()
                }

                return this.setResponse(e, 'error')
                // return this.setResponse(e, 'success')
            })

        // console.log(response)
        if (!response.status)
            return response
        return this.setResponse(response, 'success')
    }

    setResponse(response, type) {
        if (type === 'success') {
            const data = _.isUndefined(response.data?.info?.token_info) ? response.data : response.data?.info?.token_info
            const status = [200, 201, 202].includes(response.status)
            const message = response.statusText
            return {
                status: status,
                code: response.status,
                message: message,
                body: data
            }
        } else {
            const status = ""
            const message = ""
            return {
                status: false,
                code: response.response.status,
                message: response.response.data,
                body: response
            }
        }
    }

    redirectToLoginPage() {
        window.eticpos_config.token = null
        localStorage.removeItem('token')
        store.commit('setTab', 'login')
    }
}

export default new Apiclient()
<template>
  <div class="eticpos-card">
    <div class="eticpos-card-header">
      <slot name="header" />
    </div>
    <div class="eticpos-card-body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.eticpos-card {
  border: var(--card-border-width) var(--card-border-style) var(--card-border-color);
  border-radius: 7px;
  .eticpos-card-header {
    border-bottom: var(--card-border-width) var(--card-border-style) var(--card-border-color);
    padding: .5rem .7rem;
    background-color: #f9f9f9;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .eticpos-card-body {
    padding: .7rem;
  }
}
</style>
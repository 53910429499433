<template>
  <nav class="navbar">
    <div class="topBar">
      <div class="container">
        <img src="https://i0.wp.com/eticsoft.com/wp-content/uploads/2021/12/cropped-eticsoft-logo.png?w=352&ssl=1" alt="" v-on:click="$store.commit('setTab', 'home'); isOpenMenu()" />
        <div>
          <select v-on:change="changeLang()">
            <option v-for="lang in $i18n.availableLocales" :key="lang" :value="lang" v-text="lang" :selected="$i18n.locale === lang"></option>
          </select>
          <button v-on:click="logout()" class="logout">
            <img :src="require('@/assets/svg/logout.svg')" alt="logout" class="logoutImg">
          </button>
        </div>
      </div>
    </div>
    <div class="navigationBar">
      <div class="container">
        <button class="navbarButton" v-on:click="isOpenMenu()">
          ✣
        </button>
        <ul :class="{active: isOpen}">
          <li class="navigation-item" :class="{active: $store.getters.getTab === 'home'}">
            <button v-on:click="$store.commit('setTab', 'home'); isOpenMenu()">
              {{ $t('page.home.navTitle') }}
            </button>
          </li>
          <li class="navigation-item" :class="{active: $store.getters.getTab === 'user'}">
            <button v-on:click="$store.commit('setTab', 'user'); isOpenMenu()">
              {{ $t('page.user.navTitle') }}
            </button>
          </li>
          <li class="navigation-item" :class="{active: $store.getters.getTab === 'listAllGateways' || $store.getters.getTab === 'listGatewaysSetting'}">
            <div class="dropdown">
              <button>{{ $t('page.gateway.navTitle') }}</button>
              <div class="dropdown-content">
                <ul>
                  <li v-on:click="$store.commit('setTab', 'listAllGateways'); isOpenMenu()">
                    {{ $t('page.gateway.subMenu.listAllGateways.navTitle') }}
                  </li>
                  <li v-on:click="$store.commit('setTab', 'listGatewaysSetting'); isOpenMenu()">
                    {{ $t('page.gateway.subMenu.listGatewaysSettings.navTitle') }}
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="navigation-item" :class="{active: $store.getters.getTab === 'platform'}">
            <button v-on:click="$store.commit('setTab', 'platform'); isOpenMenu()">
              {{ $t('page.platform.navTitle') }}
            </button>
          </li>
          <li class="navigation-item" :class="{active: $store.getters.getTab === 'payment' || $store.getters.getTab === 'paymentDetaily'}">
            <button v-on:click="$store.commit('setTab', 'payment'); isOpenMenu()">
              {{ $t('page.payment.navTitle') }}
            </button>
          </li>
          <li class="navigation-item" :class="{active: $store.getters.getTab === 'campaigns'}">
            <button v-on:click="$store.commit('setTab', 'campaigns'); isOpenMenu()">
              {{ $t('page.campaigns.navTitle') }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  data () {
    return {
      isOpen: false
    }
  },
  mounted() {
    console.log(this.$i18n.availableLocales);
  },
  methods: {
    isOpenMenu () {
      this.isOpen = !this.isOpen
    },
    logout() {
      this.Notification('success', 'right-bottom', '', 'Logout successfully', 5, true)
      this.Apiclient.redirectToLoginPage()
    },
    changeLang() {
      if (this.$i18n.mode === 'legacy') {
        this.$i18n.locale = event.target.value
      } else {
        this.$i18n.locale = event.target.value
      }
      localStorage.setItem('locale', event.target.value)
    }
  }
}
</script>

<style lang="scss">

@media (min-width: 640px) {
  .navbar {
    .navigationBar {
      .container {
        .navbarButton {
          display: none;
        }
        ul {
          display: block;
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .navbar {
    .navigationBar {
      .container {
        position: relative;
        padding: 0.5rem 10px;
        .navbarButton {
          display: block;
          background-color: #fff;
          border: 1px solid #dedede;
        }
        ul {
          display: none!important;
          position: absolute;
          left: 0;
          right: 0;
          &.active {
            display: block!important;
            width: 100%;
            background-color: #fff;
            border-bottom: 1px solid #dedede;
            z-index: 10;
          }
        }
      }
    }
  }
}
.navbar {
  margin-bottom: 0;
  .topBar {
    border-bottom: 1px solid #dedede;
    background: #f6f8fa;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;
      &::before, &::after {
        content: none;
      }
      img {
        height: 35px;
      }
      div {
        display: flex;
        align-items: center;
        select {
          outline: none;
          background-color: transparent;
          border: 1px solid #dedede;
          padding: 7.5px 7.5px 7.5px 7.5px;
          border-radius: 7px;
          font-size: large;
          margin-right: 5px;
        }
        button {
          &.logout {
            background-color: transparent;
            border: 1px solid #dedede;
            padding: 7.5px 7.5px 6.5px 7.5px;
            border-radius: 7px;
            .logoutImg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .navigationBar {
    border-bottom: 1px solid #dedede;
    .container {
      ul {
        display: flex;
        // gap: .5rem;
        padding: 0.5rem 0;
        margin: 0;
        list-style: none;
        .navigation-item {
          button {
            border-radius: 40px;
            padding: 2px 10px;
            cursor: pointer;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 1px;
            background: #fff;
            border: 0;
            width: 100%;
            &:hover {
              background-color: #e7e7e7;
            }
          }
          .dropdown {
            position: relative;
            &:hover{
              .dropdown-content {
                margin-top: 50px;
                display: block;
              }
            }
            .dropdown-content {
              z-index: 10;
              position: absolute;
              bottom: 0;
              // top: 32px;
              display: none;
              background-color: #fff;
              padding: 7px 14px;
              border: 1px solid #dedede;
              border-radius: 7px;
              -webkit-transform: translateY(100%); //if your div is 200px, this will move it down by 200px, if it is 100px it will down by 100px etc
              transform: translateY(100%);         //if your div is 200px, this will move it down by 200px, if it is 100px it will down by 100px etc
              ul {
                display: flex;
                flex-direction: column;
                width: 100%;
                li {
                  width: max-content;
                  cursor: pointer;
                  margin-bottom: .5rem;
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          &.active {
            button {
              //background-color: #f0ffff;
              background-color: #625afa;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>

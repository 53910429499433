import { createStore } from 'vuex'

export default createStore({
  state: {
    tab: {
      active: 'home',
      params: {}
    }
  },
  getters: {
    getTab: function (state) {
      return state.tab.active
    },
    getTabParams: function (state) {
      return state.tab.params
    }
  },
  mutations: {
    setTab: function (state, tab) {
      state.tab.active = tab
    },
    setParams: function (state, params) {
      state.tab.params = params
    },
    setTabParamsRemove: function (state) {
      state.tab.params = {}
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <LoginView v-if="$store.getters.getTab === 'login'" />
  <DefaultLayouts v-else>
    <HomeView v-if="$store.getters.getTab === 'home'" />
    <UserListView v-if="$store.getters.getTab === 'user'" />
    <UserEditView v-if="$store.getters.getTab === 'userEdit'" />
    <ListAllGateways v-if="$store.getters.getTab === 'listAllGateways'" />
    <ListGatewaysSetting v-if="$store.getters.getTab === 'listGatewaysSetting'" />
    <PlatformList v-if="$store.getters.getTab === 'platform'" />
    <PaymentList v-if="$store.getters.getTab === 'payment'" />
    <PaymentDetaily v-if="$store.getters.getTab === 'paymentDetaily'" />
    <Campaigns v-if="$store.getters.getTab === 'campaigns'" />
  </DefaultLayouts>
</template>

<script>
import LoginView from '@/theme/default/views/Login.vue'
import HomeView from '@/theme/default/views/Home.vue'

import UserListView from '@/theme/default/views/user/List'
import UserEditView from '@/theme/default/views/user/Edit'

import ListAllGateways from '@/theme/default/views/gateway/ListAllGateways'
import ListGatewaysSetting from '@/theme/default/views/gateway/ListGatewaysSetting'

import PlatformList from '@/theme/default/views/platform/List'

import PaymentList from '@/theme/default/views/payment/List'
import PaymentDetaily from '@/theme/default/views/payment/Detaily'

import Campaigns from '@/theme/default/views/campaigns/List'

import DefaultLayouts from "@/theme/default/layouts/Default";
export default {
  name: 'App',
  components: {
    DefaultLayouts,
    LoginView,
    HomeView,
    Campaigns,

    UserListView,
    UserEditView,

    ListAllGateways,
    ListGatewaysSetting,
    PlatformList,

    PaymentList,
    PaymentDetaily
  }
}
</script>

<style lang="scss">
html {
  --card-border-width: 1px;
  --card-border-style: solid;
  --card-border-color: #dedede;
}
// sm	640px	@media (min-width: 640px) { ... }
// md	768px	@media (min-width: 768px) { ... }
// lg	1024px	@media (min-width: 1024px) { ... }
// xl	1280px	@media (min-width: 1280px) { ... }
// 2xl	1536px	@media (min-width: 1536px) { ... }
.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding: 0 15px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0 15px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding: 0 15px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding: 0 15px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding: 0 15px;
  }
}
body {
  margin: 0;
}
#eticposApp {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: #ffffff;
}
</style>

<template>
<nav class="eticpos-breadcrumb-nav" aria-label="breadcrumb">
  <ol class="eticpos-breadcrumb">
    <li :class="['breadcrumb-item']">
      <button v-on:click="$store.commit('setTab', 'home')">
        {{ $t('page.home.navTitle') }}
      </button>
    </li>
    <template v-for="breadcrump in data">
      <li :class="['breadcrumb-item', {'active': breadcrump.default}]">
        <button :class="{'active': breadcrump.default}" v-if="breadcrump.default">{{ breadcrump.title }}</button>
        <button v-on:click="$store.commit('setTab', breadcrump.action)" v-else >{{ breadcrump.title }}</button>
      </li>
    </template>
  </ol>
</nav>
</template>

<script>
export default {
  props: {
    data: Object
  }
}
</script>

<style lang="scss">
$breadcrumb-divider-color: #236c757d;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E"); // "<svg width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#000'/></svg>";

.eticpos-breadcrumb-nav {
    background-color: #fafafa;

    .eticpos-breadcrumb {
        display: flex;
        gap: .2rem;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        padding-block-start: 3.5em;
        padding-block-end: 3.5em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding-inline-start: 0;

        .breadcrumb-item {
            display: flex;
            align-items: center;

            &::after {
                color: $breadcrumb-divider-color;
                content: $breadcrumb-divider;
                padding-left: .2rem;
            }
            &:last-child{
                &::after {
                    display: block;
                    float: left;
                    color: $breadcrumb-divider-color;
                    content: none;
                }
            }

            button {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                font-weight: 600;
                font-size: larger;

                &.active {
                    cursor: default;
                }
            }
        }
    }
}
</style>

const NotificationContainer = () => {
  const body = document.querySelector('body');
  const notificationContainerElement = document.createElement('div');
  notificationContainerElement.classList.add('eticpos-notification-container');
  notificationContainerElement.setAttribute('style', 'position: fixed;top: 0;width: 100%;height: 100%;z-index: 9000;pointer-events: none;left: 0;right: 0;bottom: 0;');

  const toastLeftTop = document.createElement('div');
  toastLeftTop.classList.add('toast-container', 'fixed', 'left-top');
  toastLeftTop.setAttribute('style', 'position: absolute; left: 0; top: 0; max-width: 350px; width: 350px; pointer-events: all;');
  notificationContainerElement.appendChild(toastLeftTop);

  const toastCenterTop = document.createElement('div');
  toastCenterTop.classList.add('toast-container', 'fixed', 'center-top');
  toastCenterTop.setAttribute('style', 'position: absolute; left: 50%; transform: translateX(-50%); top: 0; max-width: 350px; width: 350px; pointer-events: all;');
  notificationContainerElement.appendChild(toastCenterTop);

  const toastRightTop = document.createElement('div');
  toastRightTop.classList.add('toast-container', 'fixed', 'right-top');
  toastRightTop.setAttribute('style', 'position: absolute; right: 0; top: 0; max-width: 350px; width: 350px; pointer-events: all;');
  notificationContainerElement.appendChild(toastRightTop);
  
  const toastLeftBottom = document.createElement('div');
  toastLeftBottom.classList.add('toast-container', 'fixed', 'left-bottom');
  toastLeftBottom.setAttribute('style', 'position: absolute; left: 0; bottom: 0; max-width: 350px; width: 350px; pointer-events: all;');
  notificationContainerElement.appendChild(toastLeftBottom);

  const toastCenterBottom = document.createElement('div');
  toastCenterBottom.classList.add('toast-container', 'fixed', 'center-bottom');
  toastCenterBottom.setAttribute('style', 'position: absolute; left: 50%; transform: translateX(-50%); bottom: 0; max-width: 350px; width: 350px; pointer-events: all;');
  notificationContainerElement.appendChild(toastCenterBottom);

  const toastRightBottom = document.createElement('div');
  toastRightBottom.classList.add('toast-container', 'fixed', 'right-bottom');
  toastRightBottom.setAttribute('style', 'position: absolute; right: 0; bottom: 0; max-width: 350px; width: 350px; pointer-events: all;');
  notificationContainerElement.appendChild(toastRightBottom);

  body.appendChild(notificationContainerElement);
}

const Notification = (type = 'default', position = 'right-bottom', title = null, message, timeout = 5, autoDelete = false) => {
  console.log('mesage', typeof message);
  if (typeof message === 'object') {
    var messageString = [];
    message.map(m => {
      messageString.push(m)
    });
    messageString = messageString.join('\n <br />')
  }
  console.log('mesage', messageString);

  const randRemoveId = 'delete-' + Math.random().toString().substr(2);
  if (autoDelete) {
    setTimeout(() => {
      document.getElementById(randRemoveId).remove()
    }, timeout * 1000);
  }

  const toast = document.createElement('div');
  toast.classList.add('toast-container', 'fixed', 'right-bottom');
  toast.setAttribute('style', 'border-radius: .375rem; padding: .25rem .75rem');
  toast.setAttribute('id', randRemoveId);
  
  const toastElement = document.createElement('div');
  toastElement.classList.add('toast', 'fade', 'hide');
  toastElement.style.borderRadius = '0.375rem';
  if (type === 'success') {
    // const bgColor = 'background-color: #0f5132; color: #0f5132; border: 1px solid #badbcc;';
    toastElement.style.backgroundColor = '#d1e7dd';
    toastElement.style.color = '#0f5132';
    toastElement.style.borderColor = '#badbcc';
    toastElement.style.borderStyle = 'solid';
    toastElement.style.borderWidth = '1px';
  } else if (type === 'primary') {
    // const bgColor = 'background-color: #cfe2ff; color: #084298; border: 1px solid #b6d4fe;';
    toastElement.style.backgroundColor = '#cfe2ff';
    toastElement.style.color = '#084298';
    toastElement.style.borderColor = '#b6d4fe';
    toastElement.style.borderStyle = 'solid';
    toastElement.style.borderWidth = '1px';
  } else if (type === 'warning') {
    // const bgColor = 'background-color: #fff3cd; color: #664d03; border: 1px solid #ffecb5;';
    toastElement.style.backgroundColor = '#fff3cd';
    toastElement.style.color = '#664d03';
    toastElement.style.borderColor = '#ffecb5';
    toastElement.style.borderStyle = 'solid';
    toastElement.style.borderWidth = '1px';
  } else if (type === 'info') {
    // const bgColor = 'background-color: #cff4fc; color: #055160; border: 1px solid #b6effb;';
    toastElement.style.backgroundColor = '#cff4fc';
    toastElement.style.color = '#055160';
    toastElement.style.borderColor = '#b6effb';
    toastElement.style.borderStyle = 'solid';
    toastElement.style.borderWidth = '1px';
  } else if (type === 'danger') {
    // const bgColor = 'background-color: #f8d7da; color: #842029; border: 1px solid #f5c2c7;';
    toastElement.style.backgroundColor = '#f8d7da';
    toastElement.style.color = '#842029';
    toastElement.style.borderColor = '#f5c2c7';
    toastElement.style.borderStyle = 'solid';
    toastElement.style.borderWidth = '1px';
  } else {
    // const bgColor = 'background-color: #e2e3e5; color: #41464b; border: 1px solid #d3d6d8;';
    toastElement.style.backgroundColor = '#e2e3e5';
    toastElement.style.color = '#41464b';
    toastElement.style.borderColor = '#d3d6d8';
    toastElement.style.borderStyle = 'solid';
    toastElement.style.borderWidth = '1px';
  }
 
  // ---- Header start
  const toastElementHeader = document.createElement('div');
  toastElementHeader.classList.add('toast-header');
  toastElementHeader.setAttribute('style', 'display: flex; justify-content: space-between; align-items: center; border-bottom-style: solid; border-bottom-width: 2px;')
  
  const toastElementHeaderTitle = document.createElement('strong');
  toastElementHeaderTitle.setAttribute('style', 'word-wrap: break-word;')
  toastElementHeaderTitle.textContent = title;
  toastElementHeader.appendChild(toastElementHeaderTitle);
 
  const toastElementHeaderButton = document.createElement('button');
  toastElementHeaderButton.setAttribute('style', 'padding: .3rem .4rem; border: 0; border-radius: 0.375rem; opacity: .5; box-sizing: content-box; width: 1em; height: 1em; background: transparent url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23000\'%3e%3cpath d=\'M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z\'/%3e%3c/svg%3e") center/1em auto no-repeat');
  toastElementHeader.appendChild(toastElementHeaderButton);
  toastElementHeader.addEventListener('click', function () {
    document.getElementById(randRemoveId).remove()
  })

  toastElement.appendChild(toastElementHeader);
  // ---- Header End
  
  const toastElementBody = document.createElement('div');
  toastElementBody.classList.add('toast-body');
  toastElementBody.setAttribute('style', 'padding: .3rem .4rem; font-size: 17px; word-wrap: break-word;');
  toastElementBody.textContent = message;
  toastElement.appendChild(toastElementBody);

  toast.appendChild(toastElement);
  
  const container = document.querySelector(`body > div.eticpos-notification-container > div.toast-container.${position}`)
  container.appendChild(toast)
}

export {
    NotificationContainer,
    Notification
}
<template>
<Breadcrumb  :data="[ { default: false, title: $t('page.payment.title'), action: 'payment' }, { default: true, title: $t('page.paymentDetails.title') } ]" />
<div class="container">
  <div class="payment">
    <div class="head">
      <div class="detaily">
        <div class="label">{{ $t('page.paymentDetails.title') }}</div>
        <div class="copyId">
          <img :src="require('@/assets/svg/copy.svg')" alt="copyIcon" v-on:click="copy(params.id)">
          <div>{{ params.id }}</div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="paymentDetaily">
        <h5 class="title">{{ $t('page.paymentDetails.table.header.paymentDetails') }}</h5>
        <table>
          <tr>
            <th>{{ $t('page.paymentDetails.table.user') }}</th>
            <td>{{ params.user }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.amount') }}</th>
            <td>{{ params.amount }} {{ params.currency }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.buyerFee') }}</th>
            <td>{{ params.buyer_fee ?? 0 }} {{ params.currency }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.gatewayFee') }}</th>
            <td>{{ params.gateway_fee ?? 0 }} {{ params.currency }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.net') }}</th>
            <td>{{ params.amount - params.buyer_fee }} {{ params.currency }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.status') }}</th>
            <td>{{ params.status }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.cancelable') }}</th>
            <td>
              <img :src="require('@/assets/svg/check-success.svg')" alt="" v-if="params.cancelable" style="width:15px;height:15px" />
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.paymentLink') }}</th>
            <td>
              <a :href="params.payment_link" target="_blank">{{ $t('general.action.go') }}</a>
              <button class="noActive" v-on:click="copy(params.payment_link)">{{ $t('general.action.copy') }}</button>
            </td>
          </tr>
        </table>
      </div>
      <div class="paymentDetaily">
        <h5 class="title">{{ $t('page.paymentDetails.table.header.paymentMethod') }}</h5>
        <table>
          <tr>
            <th>ID</th>
            <td>{{ params.id }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.merchantReference') }}</th>
            <td>{{ params.merchant_reference }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.paymentType') }}</th>
            <td>{{ params.payment_type }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.paymentInstrument') }}</th>
            <td>{{ params.payment_instrument ?? '-' }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.gatewayName') }}</th>
            <td>{{ params.gateway_name ?? '-' }}</td>
          </tr>
        </table>
      </div>
      <div class="paymentDetaily">
        <h5 class="title">{{ $t('page.paymentDetails.table.header.other') }}</h5>
        <table>
          <tr>
            <th>{{ $t('page.paymentDetails.table.dateCreate') }}</th>
            <td>{{ params.date_create ?? '-' }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.dateUpdate') }}</th>
            <td>{{ params.date_update ?? '-' }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.dateUpdate') }}</th>
            <td>{{ params.date_payment ?? '-' }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.resultCode') }}</th>
            <td>{{ params.result_code ?? '-' }}</td>
          </tr>
          <tr>
            <th>{{ $t('page.paymentDetails.table.resultMessage') }}</th>
            <td>{{ params.result_message ?? '-' }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@/theme/default/components/Breadcrumb.vue';
import { Notification } from '@/plugin/notification';

export default {
  name: "Detaily",
  components: { Breadcrumb },
  data () {
    return {
      params: {}
    }
  },
  mounted() {
    console.log(this.$store.getters.getTabParams)
    this.params = this.$store.getters.getTabParams
  },
  methods: {
    copy(url) {
      Notification('success', 'right-bottom', '', 'Copied successfully', 5, true)
      navigator.clipboard.writeText(url)
    }
  }
}
</script>

<style lang="scss">
.payment {
  margin: 25px 0;

  .head {
    .detaily {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dedede;
      padding-bottom: 15px;
      margin-bottom: 35px;

      .label {
        font-size: 20px;
        font-weight: 500;
      }

      .copyId {
        display: flex;
        align-items: center;
        gap: .75rem;

        img {
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }

  .body {
    .paymentDetaily {
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #dedede;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      table {
        tr {

          th,
          td {
            padding: 5px 10px;
            font-size: 15px;
            font-weight: 500;
          }

          th {
            padding-left: 0;
            text-align: left;
            font-weight: bold;
            width: 175px;
          }

          td {
            word-break: break-all;

            button {
              text-decoration: none;
              border-radius: 40px;
              padding: 2px 10px;
              cursor: pointer;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 1px;
              background: #625afa;
              color: #fff;
              border: 0;
              display: inline-block;
              margin-left: 10px;
            }

            a {
              text-decoration: none;
              border-radius: 40px;
              padding: 2px 10px;
              cursor: pointer;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 1px;
              background: #625afa;
              color: #fff;
              border: 0;
              display: inline-block;

              &.noActive {
                cursor: pointer;

                &:focus {
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

export default {
  "general": {
    "action": {
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
      "detaily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaily"])},
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
      "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install"])},
      "installedInTheSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installed in the System"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "setUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Up"])},
      "uninstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UnInstall"])}
    }
  },
  "page": {
    "campaigns": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])}
    },
    "gateway": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "subMenu": {
        "listAllGateways": {
          "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All gateways"])}
        },
        "listGatewaysSettings": {
          "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method settings"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method settings"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateways"])}
    },
    "home": {
      "announcements": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])},
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])}
    },
    "payment": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "table": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
        "dateCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create date "])},
        "datePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date"])},
        "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
        "lastTenPaymentsMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 10 payments made"])},
        "merchantReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant referance"])},
        "paymentInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])}
    },
    "paymentDetails": {
      "table": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "buyerFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer fee"])},
        "cancelable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelable"])},
        "dateCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create date"])},
        "datePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date"])},
        "dateUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update date"])},
        "gatewayFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway fee"])},
        "gatewayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
        "header": {
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
          "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details"])},
          "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])}
        },
        "merchantReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant referance"])},
        "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net"])},
        "paymentInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])},
        "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment link"])},
        "paymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type"])},
        "resultCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result code"])},
        "resultMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result message"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Detaily"])}
    },
    "platform": {
      "card": {
        "app_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Url"])},
        "home_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])}
      },
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])}
    },
    "user": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "list": {
        "table": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
          "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "user_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Level"])}
        }
      },
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    }
  }
}
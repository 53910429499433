<template>
  <div class="mainContainer">
    <div class="center">
      <div class="card">
        <div class="card-header">
          <img src="https://i0.wp.com/eticsoft.com/wp-content/uploads/2021/12/cropped-eticsoft-logo.png?w=352&ssl=1" alt="">
        </div>
        <div class="card-body">
          <input type="email" v-model="email" />
          <input type="password" v-model="password" />
          <button v-on:click="login()">Giriş Yap</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Login",
  data() {
    return {
      email: 'test5@eticsoft.com',
      password: '12345678'
    }
  },
  methods: {
    login() {
      this.Apiclient.Login(this.email, this.password)
        .then(res => {
          if (res.status) {
            this.Notification('success', 'right-bottom', '', 'Login successfully', 5, true)
            localStorage.setItem('token', res.body.token_string)
            window.eticpos_config.token = res.body.token_string
            this.$store.commit('setTab', 'home')
            console.log(res)
          } else {
            window.eticpos_config.token = null
            localStorage.removeItem('token')
            this.$store.commit('setTab', 'login')
          }
        })
    }
  }
}
</script>

<style lang="scss">
.mainContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  .center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .card {
      min-width: 250px;
      border: 1px solid #dedede;
      border-radius: .5rem;
      .card-header {
        padding: 10px;
        border-bottom: 1px solid #dedede;
        text-align: center;
        img {
          height: 35px;
        }
      }
      .card-body {
        padding: 10px;
        max-width: 100%;
        input {
          width: 100%;
          border: 1px solid #dedede;
          border-radius: .5rem;
          margin-bottom: .5rem;
          outline: 0;
          padding: .5rem;
          box-sizing: border-box;
        }
        button {
          width: 100%;
          border: 1px solid #dedede;
          border-radius: .5rem;
          padding: .5rem;
        }
      }
    }
  }
}
</style>
